/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import "regenerator-runtime/runtime"
import "./src/styles/global.css"
import posthog from "./src/utils/posthog"
require("prismjs/themes/prism-okaidia.css")

export const onRouteUpdate = ({ location }) => {
  posthog.capture("$pageview")
}
