import posthog from "posthog-js"

// Check if we're in the browser (Gatsby can run in Node during build)
if (typeof window !== "undefined") {
  posthog.init(process.env.GATSBY_POSTHOG_API_KEY, {
    api_host: process.env.GATSBY_POSTHOG_HOST,
  })
}

export default posthog
