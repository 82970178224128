exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-planning-day-tsx": () => import("./../../../src/pages/app-planning-day.tsx" /* webpackChunkName: "component---src-pages-app-planning-day-tsx" */),
  "component---src-pages-blog-categories-tsx": () => import("./../../../src/pages/blog/categories.tsx" /* webpackChunkName: "component---src-pages-blog-categories-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-process-tsx": () => import("./../../../src/pages/our-process.tsx" /* webpackChunkName: "component---src-pages-our-process-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tags-tsx": () => import("./../../../src/pages/projects/tags.tsx" /* webpackChunkName: "component---src-pages-projects-tags-tsx" */),
  "component---src-pages-sectors-edtech-tsx": () => import("./../../../src/pages/sectors/edtech.tsx" /* webpackChunkName: "component---src-pages-sectors-edtech-tsx" */),
  "component---src-pages-sectors-electric-vehicles-software-tsx": () => import("./../../../src/pages/sectors/electric-vehicles-software.tsx" /* webpackChunkName: "component---src-pages-sectors-electric-vehicles-software-tsx" */),
  "component---src-pages-sectors-health-tech-tsx": () => import("./../../../src/pages/sectors/health-tech.tsx" /* webpackChunkName: "component---src-pages-sectors-health-tech-tsx" */),
  "component---src-pages-sectors-index-tsx": () => import("./../../../src/pages/sectors/index.tsx" /* webpackChunkName: "component---src-pages-sectors-index-tsx" */),
  "component---src-pages-sectors-mobility-tsx": () => import("./../../../src/pages/sectors/mobility.tsx" /* webpackChunkName: "component---src-pages-sectors-mobility-tsx" */),
  "component---src-pages-sectors-tourism-apps-tsx": () => import("./../../../src/pages/sectors/tourism-apps.tsx" /* webpackChunkName: "component---src-pages-sectors-tourism-apps-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-project-details-tsx": () => import("./../../../src/templates/project-details.tsx" /* webpackChunkName: "component---src-templates-project-details-tsx" */),
  "component---src-templates-project-tag-tsx": () => import("./../../../src/templates/project-tag.tsx" /* webpackChunkName: "component---src-templates-project-tag-tsx" */),
  "component---src-templates-service-details-tsx": () => import("./../../../src/templates/service-details.tsx" /* webpackChunkName: "component---src-templates-service-details-tsx" */),
  "component---src-templates-team-details-tsx": () => import("./../../../src/templates/team-details.tsx" /* webpackChunkName: "component---src-templates-team-details-tsx" */)
}

